import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView } from './components/analytics';

import './style.css';
import Home from './views/home';
import NotFound from './views/not-found';

const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, [location]);

  return null; // This component doesn't render anything
};

const App = () => {
  return (
      <Router>
        <TrackPageViews /> {/* New component for tracking */}
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={NotFound} path="**" />
          <Redirect to="/not-found" /> {/* Update this line */}
        </Switch>
      </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
